<template>
  <div class="container-fluid my-2">
    <h4 class="text-center">طلبات التسعير</h4>

    <cards v-if="RequestFilter.filter || getRequests.requests.length > 0" :rows="getRequests.requests"
      :columns="columns" 
      :paginationInfo="{
        currentPage: currentPage,
        index: RequestFilter.skip,
        limit: RequestFilter.limit,
        remainingRows: getRequests.remainingRequests,
        rowsLength:
          getRequests.requests.length +
          getRequests.remainingRequests +
          RequestFilter.skip,
      }" :cardFillters="fillters" @changePage="changePage" @filterItems="filterItems" @clearFilter="clearFilter"
      :cardSlots="cardSlots"
      >
      <template #cardNumber="cardNumberProps">
        {{cardNumberProps.index}}
       </template>
  
      <template #counter="counterProps">
        <div class="row justify-content-around">
          <p class="col-5 m-auto">ينتهي خلال</p>
          <p class="col-5 m-auto text-danger" style="direction: ltr">
            <vue-countdown :time="RequestDate(counterProps.row.expiresAt)" :interval="100"
              v-slot="{ days, hours, minutes, seconds }">
              {{ days }} : {{ hours }} : {{ minutes }} : {{ seconds }}
            </vue-countdown>
          </p>
        </div>
      </template>
      <!-- <template #lowestOffer="lowestOfferProps">
        <div class="row justify-content-around">
          <p class="col-5 m-auto">أقل عرض</p>

          <p v-if="lowestOfferProps.row.lowestOffer == 0" class="col-5 m-auto text-danger" style="direction: ltr">
            لايوجد عروض حتى الان
          </p>
          <div v-else class="col-5 m-auto">
            <p style="display: inline-block">
              {{ lowestOfferProps.row.lowestOffer }}
            </p>
            <p style="display: inline-block" class="mr-1">ريال</p>
          </div>
        </div>
      </template> -->
      <template #actions="actionsProps">
        <div style="float: none !important; padding-left: none !important" class="text-center">
          <div>
            <button class="btn btn-success btn-sm mb-2" style="left: 0 !important"
              @click="offers($event, actionsProps.row)">
              التفاصيل
            </button>
            <button class="btn bwj-btn btn-sm mx-1 mb-2" style="left: 0 !important"
              @click="postOffer($event, actionsProps.row)">
              قدم عرضاً
            </button>
          </div>
          <!-- <div style="padding-left: 0 !important; float: none !important" class="mt-3">
            عدد العروض المقدمة:({{ actionsProps.row.offers.length }})
          </div> -->
        </div>
        <!-- <div class="text-center my-2"> -->
        <!-- <button -->
        <!-- class="btn bwj-btn btn-sm" -->
        <!-- @click="offers($event, actionsProps.row)" -->
        <!-- > -->
        <!-- استعرض العروض ({{ actionsProps.row.offers.length }}) -->
        <!-- </button> -->
        <!-- <button -->
        <!-- class="btn btn-danger btn-sm mr-1" -->
        <!-- @click="removeRequest($event, actionsProps.row)" -->
        <!-- > -->
        <!-- حذف -->
        <!-- </button> -->
        <!-- </div> -->
      </template>
    </cards>

    <div class="vld-overlay d-flex align-items-center my-modal" v-if="showModal">
      <div class="vld-background" style="background: rgba(0, 0, 0, 0.3) !important"></div>
      <transition name="fade" appear>
        <transition name="slide" appear>
          <div class="MyModal text-center" v-if="showModal">
            <div class="text-right">
              <span @click="closeModal">
                <i class="close icon" style="font-size: 1.4em"></i>
              </span>
            </div>

            <add-offer v-bind:selectedRequest="selectedRequest" class="my-modal-content"></add-offer>
          </div>
        </transition>
      </transition>
    </div>
  </div>
</template>

<script>
import addOffer from "./addOffer.vue";
import { GET_ALL_REQUSTS } from "../../graphql/queries";

import VueCountdown from "@chenfengyuan/vue-countdown";
import cards from "../../components/cards/cards.vue";
import { getModels, getFillterdModels } from "../../_helpers/getModels";
import {
  getCatrgories,
  getFillterdCatrgories,
} from "../../_helpers/getCategory";

export default {
  created() {
    if (JSON.parse(localStorage.getItem("fillters"))) {
      this.fillters = JSON.parse(localStorage.getItem("fillters"));
      this.filterItems();
    }
  },
  data() {
    return {
      columns: [
        {
          key: ".name",
          label: "القطعة",
        },
        {
          key: ".model",
          label: "السيارة",
        },
        {
          key: ".manufacturer",
          label: "الشركة",
        },
        {
          key: ".year",
          label: "سنة الصنع",
        },
      ],

      currentPage: 1,
      selectedRequest: null,
      getRequests: {
        remainingRequests:0,
        requests: []
      },
      RequestFilter: {
        skip: 0,
        limit: this.$store.getters['authentication/userType'] == "Administrator" ? 100 : (this.$store.state.glopal.window.width < 992 ? 10 : 12)
      },
      fillters: [
        { value: [], options: getModels(), label: " المصنّع/الطراز" },
        { value: [], options: getCatrgories(), label: "  الأجزاء" },
        {
          value: [null, null],
          options: getCatrgories(),
          label: "السنة",
        },
      ],
      
         cardSlots:{
         headerSlots: ["cardNumber"],
      topSlots:  null,
      bottomSlots:   ["lowestOffer", "counter", "actions"]
         },


    };
  },
  apollo: {
    getRequests: {
      query: GET_ALL_REQUSTS,
      variables() {
        return {
          RequestsFilter: this.RequestFilter,
        };
      },
      result(result) {
        this.$store.commit("glopal/isLoading", false);
      },
    },
  },
  components: {
    cards,
    "add-offer": addOffer,
    VueCountdown,
  },
  methods: {
    offers(e, payload) {
      this.$router.push("FullRequest/" + payload._id);
    },
    postOffer(e, payload) {
      console.log(payload);
      this.selectedRequest = payload;
      this.showModal = true;

    },
    closeModal() {
      console.log("modal closed");
      this.showModal = false;

    },
    formatFn(value) {
      if (value > 0) {
        return value + "ريال";
      } else {
        return "لا يوجد عروض ";
      }
    },

    changePage(index) {
      this.currentPage = index;
      this.RequestFilter.skip = (index - 1) * this.RequestFilter.limit;
      this.RequestRefetch();
    },
    filterItems() {
      localStorage.setItem("fillters", JSON.stringify(this.fillters));
      this.RequestFilter.filter = getFillterdModels(this.fillters[0].value);
      this.RequestFilter.filter.category = getFillterdCatrgories(
        this.fillters[1].value
      );

      this.RequestFilter.filter.minYear = parseInt(this.fillters[2].value[1]);
      this.RequestFilter.filter.maxYear = parseInt(this.fillters[2].value[0]);
      if (
        !this.RequestFilter.filter.model.length > 0 &&
        !this.RequestFilter.filter.category.length > 0 &&
        !this.RequestFilter.filter.minYear &&
        !this.RequestFilter.filter.maxYear
      ) {
        delete this.RequestFilter.filter;
        this.currentPage = 1;
      } else {
        if (!this.RequestFilter.filter.model.length > 0) {
          delete this.RequestFilter.filter.model;
          // delete this.RequestFilter.filter.manufacturer;
        }
        if (!this.RequestFilter.filter.category.length > 0) {
          delete this.RequestFilter.filter.category;
        }
        if (!this.RequestFilter.filter.minYear) {
          delete this.RequestFilter.filter.minYear;
        }
        if (!this.RequestFilter.filter.maxYear) {
          delete this.RequestFilter.filter.maxYear;
        }
      }

      this.RequestFilter.skip = null;
      this.RequestRefetch();
      return;
    },
    RequestRefetch() {
      this.$apollo.queries.getRequests.refetch().then((value) => {
        this.$store.commit("glopal/isLoading", false);
      });
    },
    RequestDate(date) {
      const now = new Date();
      return parseInt(date) - now;
    },
    clearFilter() {
      for (let index = 0; index < this.fillters.length; index++) {
        if (this.fillters[index].label == "السنة") {
          this.fillters[index].value = [null, null];
        } else {
          this.fillters[index].value = [];
        }
      }

      this.filterItems();
      return;
    },
  },
  computed: {
    showModal: {
      set(value) {
        this.$store.state.glopal.showModal = value;
        return;
      },
      get() {
        return this.$store.state.glopal.showModal;
      },
    },

  },
};
</script>

<style>
img.preview {
  width: auto;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

input[type="file"] {
  display: none;
}

.custom-file-input::-webkit-file-upload-text {
  visibility: hidden;
}

.custom-file-input::before {
  visibility: visible;
  content: "Upload Your Image";
  -webkit-user-select: none;
  cursor: pointer;
}

.my-modal-content {

  text-align: center;
  padding: 2em;

}
.my-modal {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  overflow: auto;
}
</style>
